import React, { useState } from "react";
import {
    makeStyles,
    Typography,
    Grid,
    Backdrop,
    Paper,
    Card,
    CardContent,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory } from "react-router-dom";
import { getNotifications, deleteNotification, } from "../../../actions/notificationActions";
import {  connect, useDispatch } from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 18,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight:'bold',
        marginBottom: 30,
    },
    description: {
        display: "block",
        textTransform:'none',
        lineHeight:2,
        fontSize: 14,
        fontFamily: "Rubik",
        fontWeight:'400',
        marginBottom: 20,
    },
    ship: {
        width: 100,
        height: 100,
        textAlign:'center',
        margin: 'auto',
        display:'block',

      },
      zeroContainer: {
        padding: theme.spacing(0.5),
      },
      zeroNotify: {
        display: "block",
        margin: "30px 0",
        textAlign:'center',
        letterSpacing: 2.5,
        fontSize: 16,
        lineHeight: 1.7,
      },
    grid:{
        padding: 10,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    bgColor:{
        backgroundColor: theme.palette.type === "light"
            ? theme.palette.background.paper
            : "#fafafa",
        padding: 20,
        border:'1px solid #ccc',
        textAlign: 'left',
        marginTop: 10,
        transition: 'ease-in 0.2s all',
        boxShadow: '0 0 10px #ccc',
        width: '100%',
        color:
            theme.palette.type === "dark"
                ? theme.palette.primary.dark
                : theme.palette.common.white,
        "&:hover": {
            backgroundColor: "#655757",
            boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
        },
    }
}));

const Notifications = ({ getNotifications, notifications }) => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const [openBackdrop, setOpenBackdrop] = useState(false);

    React.useEffect(() => {
        setOpenBackdrop((prev) => !prev);
        getNotifications().then(() => {
            setOpenBackdrop(false);
        });
    }, [getNotifications]);

    const handleNotificationRoute = (path, id) => {
        if (path) {
            history.push(path);
            setTimeout(() => {
                dispatch(deleteNotification(id));
            }, 2000);
        }
    };   

    const handleDeleteNotification = (id) => {
        dispatch(deleteNotification(id));
    };
    
    return (
        <div>
            <Typography variant="overline" className={classes.title}>
                Notifications
            </Typography>

            <Paper>
                <Card className="card flex-fill">
                    <CardContent className="card-body">
                        <Grid container spacing={2}>
                            {(notifications && notifications.length > 0) ?  
                                <div className="employee-noti-content notification_page_pad">
                                    {notifications && notifications.map((notif, i) => (
                                        <Grid item xs={12} md={12} key={i}>
                                            <div className="employee-notification-grid" style={{ width:"100%", marginBottom: 20, cursor:"pointer" }} >
                                                <div className="employee-notification-icon">
                                                    <span className="badge-soft-danger rounded-circle">{i + 1}</span>
                                                </div>
                                                <div className="employee-notification-content">
                                                    <h6 onClick={() => handleNotificationRoute(notif.path, notif._id)}>
                                                        {notif?.title && notif?.title} 
                                                    </h6>
                                                    
                                                    <p>{notif?.body && notif?.body}</p>
                                                    <li>{notif?.createdAt && moment(notif?.createdAt).fromNow()}</li>
                                                </div>
                                                <div className="employee-notification-button">
                                                    <IconButton
                                                        color="secondary"
                                                        onClick={() => handleDeleteNotification(notif._id)}
                                                        style={{float:"right"}}
                                                    >
                                                        <DeleteIcon color="secondary" />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </Grid>
                                    ))}
                                </div>
                            : 
                            <div className="employee-noti-content notification_page_pad">
                                <img
                                    src="/img/high-five-1.svg"
                                    alt="ship"
                                    className={classes.ship}
                                />
                                <Typography variant="overline" className={classes.zeroNotify}>
                                    You Don't Have Any Notification
                                </Typography>
                            </div>
                            }
                        </Grid>
                    </CardContent>
                </Card>
            </Paper>
            <Backdrop className={classes.backdrop} open={openBackdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}

const mapStateToProps = (state) => ({
    notifications: state.notification.notifications,
});

const mapDispatchToProps = {
    getNotifications,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);