module.exports = {    
    // Receiving and Inspection
    CREATE_RECEIVING_INSPECTION: "CREATE_RECEIVING_INSPECTION",
    CREATE_RECEIVING_INSPECTION_REQUEST: "CREATE_RECEIVING_INSPECTION_REQUEST",
    CREATE_RECEIVING_INSPECTION_FAIL: "CREATE_RECEIVING_INSPECTION_FAIL",

    GET_ALL_RECEIVING_INSPECTIONS: "GET_ALL_RECEIVING_INSPECTIONS",
    GET_ALL_RECEIVING_INSPECTIONS_REQUEST: "GET_ALL_RECEIVING_INSPECTIONS_REQUEST",
    GET_ALL_RECEIVING_INSPECTIONS_FAIL: "GET_ALL_RECEIVING_INSPECTIONS_FAIL",

    GET_ACCEPTED_RECEIVING_INSPECTIONS: "GET_ACCEPTED_RECEIVING_INSPECTIONS",
    GET_ACCEPTED_RECEIVING_INSPECTIONS_REQUEST: "GET_ACCEPTED_RECEIVING_INSPECTIONS_REQUEST",
    GET_ACCEPTED_RECEIVING_INSPECTIONS_FAIL: "GET_ACCEPTED_RECEIVING_INSPECTIONS_FAIL",

    GET_RECEIVING_INSPECTION: "GET_RECEIVING_INSPECTION",
    GET_RECEIVING_INSPECTION_REQUEST: "GET_RECEIVING_INSPECTION_REQUEST",
    GET_RECEIVING_INSPECTION_FAIL: "GET_RECEIVING_INSPECTION_FAIL",

    VERIFY_RECEIVING_INSPECTION: "VERIFY_RECEIVING_INSPECTION",
    VERIFY_RECEIVING_INSPECTION_REQUEST: "VERIFY_RECEIVING_INSPECTION_REQUEST",
    VERIFY_RECEIVING_INSPECTION_FAIL: "VERIFY_RECEIVING_INSPECTION_FAIL",

    UPDATE_RECEIVING_INSPECTION: "UPDATE_RECEIVING_INSPECTION",
    UPDATE_RECEIVING_INSPECTION_REQUEST: "UPDATE_RECEIVING_INSPECTION_REQUEST",
    UPDATE_RECEIVING_INSPECTION_FAIL: "UPDATE_RECEIVING_INSPECTION_FAIL",

    DELETE_RECEIVING_INSPECTION: "DELETE_RECEIVING_INSPECTION",
    DELETE_RECEIVING_INSPECTION_REQUEST: "DELETE_RECEIVING_INSPECTION_REQUEST",
    DELETE_RECEIVING_INSPECTION_FAIL: "DELETE_RECEIVING_INSPECTION_FAIL",

    SUBMIT_RECEIVING_INSPECTION: "SUBMIT_RECEIVING_INSPECTION",
    SUBMIT_RECEIVING_INSPECTION_REQUEST: "SUBMIT_RECEIVING_INSPECTION_REQUEST",
    SUBMIT_RECEIVING_INSPECTION_FAIL: "SUBMIT_RECEIVING_INSPECTION_FAIL",

    INSPECT_RECEIVING_INSPECTION: "INSPECT_RECEIVING_INSPECTION",
    INSPECT_RECEIVING_INSPECTION_REQUEST: "INSPECT_RECEIVING_INSPECTION_REQUEST",
    INSPECT_RECEIVING_INSPECTION_FAIL: "INSPECT_RECEIVING_INSPECTION_FAIL",

    APPROVE_RECEIVING_INSPECTION: "APPROVE_RECEIVING_INSPECTION",
    APPROVE_RECEIVING_INSPECTION_REQUEST: "APPROVE_RECEIVING_INSPECTION_REQUEST",
    APPROVE_RECEIVING_INSPECTION_FAIL: "APPROVE_RECEIVING_INSPECTION_FAIL",

    DISAPPROVE_RECEIVING_INSPECTION: "DISAPPROVE_RECEIVING_INSPECTION",
    DISAPPROVE_RECEIVING_INSPECTION_REQUEST: "DISAPPROVE_RECEIVING_INSPECTION_REQUEST",
    DISAPPROVE_RECEIVING_INSPECTION_FAIL: "DISAPPROVE_RECEIVING_INSPECTION_FAIL",

    REJECT_RECEIVING_INSPECTION: "REJECT_RECEIVING_INSPECTION",
    REJECT_RECEIVING_INSPECTION_REQUEST: "REJECT_RECEIVING_INSPECTION_REQUEST",
    REJECT_RECEIVING_INSPECTION_FAIL: "REJECT_RECEIVING_INSPECTION_FAIL",

    DECLINE_RECEIVING_INSPECTION: "DECLINE_RECEIVING_INSPECTION",
    DECLINE_RECEIVING_INSPECTION_REQUEST: "DECLINE_RECEIVING_INSPECTION_REQUEST",
    DECLINE_RECEIVING_INSPECTION_FAIL: "DECLINE_RECEIVING_INSPECTION_FAIL",

    REVIEW_RECEIVING_INSPECTION: "REVIEW_RECEIVING_INSPECTION",
    REVIEW_RECEIVING_INSPECTION_REQUEST: "REVIEW_RECEIVING_INSPECTION_REQUEST",
    REVIEW_RECEIVING_INSPECTION_FAIL: "REVIEW_RECEIVING_INSPECTION_FAIL",

    RESET_RECEIVING_INSPECTION: "RESET_RECEIVING_INSPECTION",

    // Work Completion
    CREATE_WORK_COMPLETION: "CREATE_WORK_COMPLETION",
    CREATE_WORK_COMPLETION_REQUEST: "CREATE_WORK_COMPLETION_REQUEST",
    CREATE_WORK_COMPLETION_FAIL: "CREATE_WORK_COMPLETION_FAIL",

    GET_ALL_WORK_COMPLETIONS: "GET_ALL_WORK_COMPLETIONS",
    GET_ALL_WORK_COMPLETIONS_REQUEST: "GET_ALL_WORK_COMPLETIONS_REQUEST",
    GET_ALL_WORK_COMPLETIONS_FAIL: "GET_ALL_WORK_COMPLETIONS_FAIL",

    GET_ACCEPTED_WORK_COMPLETIONS: "GET_ACCEPTED_WORK_COMPLETIONS",
    GET_ACCEPTED_WORK_COMPLETIONS_REQUEST: "GET_ACCEPTED_WORK_COMPLETIONS_REQUEST",
    GET_ACCEPTED_WORK_COMPLETIONS_FAIL: "GET_ACCEPTED_WORK_COMPLETIONS_FAIL",

    GET_WORK_COMPLETION: "GET_WORK_COMPLETION",
    GET_WORK_COMPLETION_REQUEST: "GET_WORK_COMPLETION_REQUEST",
    GET_WORK_COMPLETION_FAIL: "GET_WORK_COMPLETION_FAIL",

    VERIFY_WORK_COMPLETION: "VERIFY_WORK_COMPLETION",
    VERIFY_WORK_COMPLETION_REQUEST: "VERIFY_WORK_COMPLETION_REQUEST",
    VERIFY_WORK_COMPLETION_FAIL: "VERIFY_WORK_COMPLETION_FAIL",

    UPDATE_WORK_COMPLETION: "UPDATE_WORK_COMPLETION",
    UPDATE_WORK_COMPLETION_REQUEST: "UPDATE_WORK_COMPLETION_REQUEST",
    UPDATE_WORK_COMPLETION_FAIL: "UPDATE_WORK_COMPLETION_FAIL",

    DELETE_WORK_COMPLETION: "DELETE_WORK_COMPLETION",
    DELETE_WORK_COMPLETION_REQUEST: "DELETE_WORK_COMPLETION_REQUEST",
    DELETE_WORK_COMPLETION_FAIL: "DELETE_WORK_COMPLETION_FAIL",

    SUBMIT_WORK_COMPLETION: "SUBMIT_WORK_COMPLETION",
    SUBMIT_WORK_COMPLETION_REQUEST: "SUBMIT_WORK_COMPLETION_REQUEST",
    SUBMIT_WORK_COMPLETION_FAIL: "SUBMIT_WORK_COMPLETION_FAIL",

    INSPECT_WORK_COMPLETION: "INSPECT_WORK_COMPLETION",
    INSPECT_WORK_COMPLETION_REQUEST: "INSPECT_WORK_COMPLETION_REQUEST",
    INSPECT_WORK_COMPLETION_FAIL: "INSPECT_WORK_COMPLETION_FAIL",

    APPROVE_WORK_COMPLETION: "APPROVE_WORK_COMPLETION",
    APPROVE_WORK_COMPLETION_REQUEST: "APPROVE_WORK_COMPLETION_REQUEST",
    APPROVE_WORK_COMPLETION_FAIL: "APPROVE_WORK_COMPLETION_FAIL",

    DISAPPROVE_WORK_COMPLETION: "DISAPPROVE_WORK_COMPLETION",
    DISAPPROVE_WORK_COMPLETION_REQUEST: "DISAPPROVE_WORK_COMPLETION_REQUEST",
    DISAPPROVE_WORK_COMPLETION_FAIL: "DISAPPROVE_WORK_COMPLETION_FAIL",

    REJECT_WORK_COMPLETION: "REJECT_WORK_COMPLETION",
    REJECT_WORK_COMPLETION_REQUEST: "REJECT_WORK_COMPLETION_REQUEST",
    REJECT_WORK_COMPLETION_FAIL: "REJECT_WORK_COMPLETION_FAIL",

    ACCEPT_WORK_COMPLETION: "ACCEPT_WORK_COMPLETION",
    ACCEPT_WORK_COMPLETION_REQUEST: "ACCEPT_WORK_COMPLETION_REQUEST",
    ACCEPT_WORK_COMPLETION_FAIL: "ACCEPT_WORK_COMPLETION_FAIL",

    DECLINE_WORK_COMPLETION: "DECLINE_WORK_COMPLETION",
    DECLINE_WORK_COMPLETION_REQUEST: "DECLINE_WORK_COMPLETION_REQUEST",
    DECLINE_WORK_COMPLETION_FAIL: "DECLINE_WORK_COMPLETION_FAIL",

    REVIEW_WORK_COMPLETION: "REVIEW_WORK_COMPLETION",
    REVIEW_WORK_COMPLETION_REQUEST: "REVIEW_WORK_COMPLETION_REQUEST",
    REVIEW_WORK_COMPLETION_FAIL: "REVIEW_WORK_COMPLETION_FAIL",

    RESET_WORK_COMPLETION: "RESET_WORK_COMPLETION",


};
