import React, { useState, useEffect } from "react";
import {
    makeStyles,
    Typography,
    Paper,
    Grid,
    Button,
    Backdrop,
    Chip,
    Menu,
    MenuItem,
    Icon,
    IconButton,
} from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import CloudDownloadOutlined from "@material-ui/icons/CloudDownloadOutlined";

import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";

import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import FilterListIcon from "@material-ui/icons/FilterList";
import Fade from "@material-ui/core/Fade";
import SearchComponent from "../../SearchComponent";
import { TablePaginationActions } from "../../TablePaginationActions";

import CreatePurchaseRequisitionModal from "../Modals/Purchasing/CreatePurchaseRequisitionModal";
import DeletePurchaseRequisitionModal from "../Modals/Purchasing/DeletePurchaseRequisitionModal";
import { getPurchaseRequisitions } from "../../../actions/purchasingActions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Feedback from "../../../Feedback";
import clsx from "clsx";
import moment from "moment";
import { ExportJsonCsv } from "react-export-json-csv";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight: "bold"
    },
    textField: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(3),
    },

    action: {
        marginBottom: 30,
    },
    btn: {
        marginTop: 30,
        marginBottom: 30,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    table: {
        minWidth: 500,
    },
    chip: {
        paddingLeft: 8,
        paddingRight: 8,
    },
    statusDarkGreen: {
        borderColor: "#4caf50",
    },
    statusGreen: {
        borderColor: "#1de9b6",
    },
    statusYellow: {
        borderColor: "#fbc02d",
    },
    statusOrange: {
        borderColor: "#ff9800",
    },
    statusRed: {
        borderColor: "#ef5350",
    },
    marginBottom: {
        marginBottom: 30,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    createBtn: {
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        paddingLeft: 20,
        paddingRight: 20,
    },
}));

const INIT_SORT = {
    order: "asc",
    orderBy: "sn",
};

const PurchaseRequisition = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [anchorEl, setAnchorEl] = useState(null);
    const [filter, setFilter] = useState("");
    const [sortedData, setSortedData] = useState([]);
    const [sorting, setSorting] = useState(INIT_SORT);
    
    const [openCreate, setOpenCreate] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [getId, setGetId] = useState("");
    const [search, setSearch] = useState("");
    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    const { purchase_requisitions, loading, error } = useSelector((state) => state.purchaseRequisition);
    const { profile } = useSelector((state) => state.user);

    const desc = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }
  
    const stableSort = (array, cmp) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = cmp(a[0], b[0]);
            if (order !== 0) return order;
                return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    }
  
    // const getSorting = (order, orderBy) => {
    //     return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
    // }

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    useEffect(() => {
        dispatch(getPurchaseRequisitions());
    }, [dispatch]);

    const navigateById = (path, id) => {
        history.push(`${path}/${id}`);
    };

    const updateSearch = (e) => {
        setSearch(e.target.value.substr(0, 20));
    };

    const ResetSearch = (e) => {
        e.preventDefault();
        setSearch("");
    };

    const getSortedData = React.useCallback(() => {
        const getSorting = (order, orderBy) => {
            return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
        }

        if (purchase_requisitions && purchase_requisitions !== null) {
            let sortedData = stableSort(purchase_requisitions, getSorting(sorting.order, sorting.orderBy));
            setSortedData(sortedData);
        }
    }, [purchase_requisitions, sorting]);

    useEffect(() => {
        getSortedData();
    }, [getSortedData]);

    let filteredRows = () =>
        sortedData === undefined
            ? []
            : sortedData &&
            sortedData.filter((row) => {
                if (search !== "") {
                    return (
                        row.documentNo.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                        row.createdBy.userName.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                        row.division?.name.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1 || 
                        row.month.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1 || 
                        row.year.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1 || 
                        row.status.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                        row.purpose.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
                    );
                } else if (filter !== "") {
                    return row.status.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
                } else {
                    return row;
                }
            });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleOpenCreate = () => {
        setOpenCreate(true);
    };
    const handleCloseCreate = () => {
        setOpenCreate(false);
    };
    const handleDeleteOpen = (id) => {
        setGetId(id);
        setOpenDelete(true);
    };
    const handleDeleteClose = () => {
        setOpenDelete(false);
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const handleOpenFilter = (event) => {
        setSorting((prevState) => ({ ...prevState, isSorting: false }));
        setAnchorEl(event.currentTarget);
    };
    const handleCloseFilter = () => {
        setAnchorEl(null);
    };

    const handleSelectFilter = (e, val) => {
        setFilter(val.substr(0, 20));
        handleCloseFilter();
    };

    const isAdmin = profile
        ? profile &&
        profile.groups &&
        profile.groups.some((grp) => grp.name === "admin") : false;

    const isDivManager = profile
        ? profile &&
        profile.groups &&
        profile.groups.some(
            (grp) => grp.name === "purchasing" && grp.module === "head"
        )
        : false;

    const isProcurement = profile
        ? profile &&
        profile.division &&
        profile?.division?.name === "Procurement Management"
        : false;

    const isEbenezer = profile
        ? profile && profile.id === "5fb66fbfc521c80017c15e89"
        : false;

    const emptyRows =
        rowsPerPage -
        Math.min(
            rowsPerPage,
            (Array(1000).length || filteredRows().length) - page * rowsPerPage
        );

    const headers = [
        { key: 'sn', name: 'SN',  },
        { key: 'documentNo', name: 'Document No', },
        { key: 'division', name: 'Division/Subdivision', },
        { key: 'dateCreated', name: 'Date Created',  },
        { key: 'requestedBy', name: 'Requested By', },
        { key: 'dateNeeded', name: 'Date Needed', },
        { key: 'totalQuantity', name: 'Total Quantity',  },
        { key: 'status', name: 'Status', },
    ];
        
    const exportData = sortedData.map((item, i) => (
        {
            sn: i + 1, 
            documentNo: item.documentNo, 
            division: item.division?.name,
            dateCreated: item.createdAt,
            requestedBy: item.createdBy?.userName,
            dateNeeded: item.dateNeeded,
            totalQuantity : item.totalQuantityNeeded,
            status: item.status,   
        }),
    );

    const EnhancedTableHead = () => {
        const createSortHandler = property => event => {
            handleRequestSort(event, property);
        };

        const headerFields = [
            { id: 'sn', label: 'SN', numeric: true, exclude: false },
            { id: 'documentNo', label: 'Document No', numeric: false, exclude: false },
            { id: 'division', label: 'Division/Subdivision', numeric: false, exclude: false },
            { id: 'dateCreated', label: 'Date Created', numeric: false, exclude: false },
            { id: 'requestedBy', label: 'Requested By', numeric: false, exclude: false },
            { id: 'dateNeeded', label: 'Date Needed', numeric: true, exclude: true },
            { id: 'totalQuantity', label: 'Total Quantity', numeric: true, exclude: true },
            { id: 'status', label: 'Status', numeric: false, exclude: false },
            { id: 'action', label: 'Action', numeric: false, exclude: true },
        ];

        const handleRequestSort = (event, property) => {
            const orderBy = property;
            let order = 'desc';

            if (sorting.orderBy === property && sorting.order === 'desc') {
                order = 'asc';
            }

            setSorting({ order, orderBy });

            getSortedData();
        };

        return (
            <TableHead>
                <TableRow>
                    {headerFields.map((field, index) => (
                        <TableCell 
                            key={index}
                            order={sorting.order}
                            sortDirection={sorting.orderBy === field.id ? sorting.order : false}
                        >
                            {field.exclude ? field.label :
                            <Tooltip
                                title={`Sort by ${field.label}`}
                                placement={field.numeric ? 'bottom-end' : 'bottom-start'}
                                enterDelay={100}
                            >
                                <TableSortLabel
                                    active={sorting.orderBy === field.id}
                                    direction={sorting.order}
                                    onClick={createSortHandler(field.id)}
                                >
                                    {field.label}
                                </TableSortLabel>
                            </Tooltip>}
                        </TableCell>
                    ))
                }
                {(isAdmin || isEbenezer) ? (<TableCell>Delete</TableCell>) : null}
                </TableRow>
            </TableHead>
        )
    }
    
    
    return (
        <div>
            {feed.success ? (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="success"
                    message={feed.message}
                />
            ) : (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="error"
                    message={feed.message}
                />
            )}
            <CreatePurchaseRequisitionModal {...{ openCreate, handleCloseCreate }} />
            <DeletePurchaseRequisitionModal {...{ openDelete, handleDeleteClose, id: getId }} />

            <Typography variant="overline" gutterBottom className={classes.title}>
                Purchase Requisition
            </Typography>
            <Grid
                container
                justify="space-between"
                alignItems="center"
                spacing={3}
                className={classes.marginBottom}
            >
                <Grid item>
                    <Button
                        color="primary"
                        variant="contained"
                        className={classes.createBtn}
                        onClick={handleOpenCreate}
                    >
                        Create
                    </Button>

                    {/* <ExportJsonCsv headers={headers} items={exportData}>
                        <Button
                            color="secondary"
                            variant="contained"
                            className={classes.createBtn}
                            startIcon={<ImportExportOutlined />}
                        >
                            Export
                        </Button>
                    </ExportJsonCsv> */}

                </Grid>
                <Grid item>
                    <SearchComponent
                        updateSearch={updateSearch}
                        placeholder="Search..."
                        search={search}
                        ResetSearch={ResetSearch}
                    />
                </Grid>
            </Grid>

            <Paper>
                <Grid container justify="space-between" alignItems="center">
                    <Grid item></Grid>
                    <Grid item>
                        <ExportJsonCsv 
                            headers={headers} 
                            items={exportData} 
                            fileTitle="purchase_requisitions"
                            style={{ marginRight: 10, marginTop: 15, border:'none', fontWeight:'normal', background:'transparent', cursor:'pointer' }}
                        >
                            <IconButton>
                                <Typography style={{color:'#999'}}> <CloudDownloadOutlined /> </Typography>
                            </IconButton>
                        </ExportJsonCsv>
                        
                        <IconButton
                            style={{ marginRight: 10, marginTop: -10 }}
                            onClick={handleOpenFilter}
                        >
                            <FilterListIcon />
                        </IconButton>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleCloseFilter}
                            TransitionComponent={Fade}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                            }}
                        >
                            <MenuItem onClick={(e) => handleSelectFilter(e, "")}>
                                All
                            </MenuItem>
                            <MenuItem onClick={(e) => handleSelectFilter(e, "Draft")}>
                                Draft
                            </MenuItem>
                            <MenuItem onClick={(e) => handleSelectFilter(e, "Submitted")}>
                                Submitted
                            </MenuItem>
                            <MenuItem onClick={(e) => handleSelectFilter(e, "Rejected")}>
                                Rejected
                            </MenuItem>
                            <MenuItem onClick={(e) => handleSelectFilter(e, "Approved")}>
                                Approved
                            </MenuItem>
                            <MenuItem onClick={(e) => handleSelectFilter(e, "Accepted")}>
                                Accepted
                            </MenuItem>
                            <MenuItem onClick={(e) => handleSelectFilter(e, "Declined")}>
                                Declined
                            </MenuItem>
                            <MenuItem onClick={(e) => handleSelectFilter(e, "Reviewed")}>
                                Reviewed
                            </MenuItem>
                        </Menu>
                    </Grid>
                </Grid>

                <TableContainer>
                    <Table className={classes.table} aria-label="custom pagination table">
                        
                        <EnhancedTableHead />

                        <TableBody>
                            {(rowsPerPage > 0
                                ? filteredRows().slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                : filteredRows()
                            ).map((row, i) => {
                                return (
                                    <TableRow hover key={row._id}>
                                        <TableCell>
                                            {i + 1}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.documentNo && row.documentNo}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.division && row.division?.name}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.createdAt && moment(row.createdAt, "MMMM Do YYYY, h:mm:ss a").format("DD-MM-YYYY")}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.createdBy && row.createdBy.userName}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.dateNeeded && moment(row.dateNeeded).format("DD-MM-YYYY")}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.totalQuantityNeeded && row.totalQuantityNeeded}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <Chip
                                                style={{ padding: 10 }}
                                                label={row.status}
                                                variant="outlined"
                                                className={clsx(
                                                    row.status === "draft" || row.status === "declined" || row.status === "rejected"
                                                        ? classes.statusYellow
                                                        : row.status === "submitted" 
                                                            ? classes.statusOrange
                                                            : row.status === "approved" || row.status === "accepted" 
                                                                ? classes.statusGreen
                                                                : row.status === "completed" || row.status === "reviewed"
                                                                    ? classes.statusDarkGreen
                                                                    : classes.statusRed,
                                                    classes.chip
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            {(row.status === "draft" || row.status === "declined" || row.status === "rejected") ? (
                                                <IconButton
                                                    onClick={() => navigateById("/purchasing/requisition/edit", row._id)}
                                                    disabled={(row.status === "submitted" || row.status === "accepted" || row.status === "approved"
                                                    || row.status === "reviewed")}
                                                    variant="extended"
                                                >
                                                    <Icon className="fas fa-pencil-alt" color="primary" />
                                                </IconButton>
                                            ) : ((isAdmin || isDivManager) && (row.status === "submitted") ? (
                                                // Division Head Approve / Reject Requisition
                                                <IconButton
                                                    color="inherit"
                                                    onClick={() => navigateById("/purchasing/requisition/view", row._id)}
                                                    disabled={row.status === "draft" || row.status === "declined" || row.status === "rejected"}
                                                    variant="extended"
                                                >
                                                    <Icon className="fas fa-eye" color="action" />
                                                </IconButton>
                                            ) : ((isAdmin || isProcurement) && (row.status === "approved")) ? (
                                                // Procurement Accepts Requisition
                                                <IconButton
                                                    color="inherit"
                                                    onClick={() => navigateById("/purchasing/requisition/view", row._id)}
                                                    disabled={row.status === "draft" || row.status === "declined" || row.status === "rejected"}
                                                    variant="extended"
                                                >
                                                    <Icon className="fas fa-eye" color="action" />
                                                </IconButton>
                                            ) : ((isAdmin || isProcurement) && (row.status === "accepted" || row.status === "reviewed")) ? (
                                                // Procurement Reviews Requisition
                                                <IconButton
                                                    color="inherit"
                                                    onClick={() => navigateById("/purchasing/requisition/review", row._id)}
                                                    disabled={row.status === "draft" || row.status === "declined" || row.status === "rejected"}
                                                    variant="extended"
                                                >
                                                    <Icon className="fas fa-eye" color="action" />
                                                </IconButton>
                                            ) : (
                                                <IconButton
                                                    color="inherit"
                                                    onClick={() => navigateById("/purchasing/requisition/view", row._id)}
                                                    //disabled={row.status !== "accepted" || row.status !== "reviewed"}
                                                    variant="extended"
                                                >
                                                    <Icon className="fas fa-eye" color="action" />
                                                </IconButton>
                                            )
                                            )}
                                        </TableCell>
                                        {(isAdmin || isEbenezer) ? (<TableCell component="th" scope="row">
                                            <IconButton
                                                variant="extended"
                                                color="secondary"
                                                disabled={(isEbenezer && (row.status !== "draft" || row.status !== "declined" || row.status !== "rejected"))}
                                                onClick={() => handleDeleteOpen(row._id)}
                                            >
                                                <Icon className="fas fa-trash" color="secondary" />
                                            </IconButton>
                                        </TableCell>) : null}
                                    </TableRow>
                                );
                            })}

                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                            {filteredRows().length === 0 && (
                                <TableRow hover>
                                    <TableCell
                                        colSpan={10}
                                        align="center"
                                        style={{ color: "#616161" }}
                                        component="th"
                                        scope="row"
                                    >
                                        No Records To Display
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    component="div"
                    rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                    colSpan={3}
                    count={filteredRows().length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        native: true,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                />
            </Paper>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default PurchaseRequisition;
