import * as types from "../types/quality";
import axios from "axios";

const token = localStorage.getItem("token:key");
const URL = `/api/v1/quality`;

//////////////////////////////////////////////
///////// Receiving and Inspection ///////////
//////////////////////////////////////////////
export const createReceivingInspection = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.CREATE_RECEIVING_INSPECTION_REQUEST,
        });
        const { data } = await axios.post(`${URL}/receiving`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.CREATE_RECEIVING_INSPECTION,
            payload: data.data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.CREATE_RECEIVING_INSPECTION_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getReceivingInspections = () => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_ALL_RECEIVING_INSPECTIONS_REQUEST,
        });
        const { data } = await axios.get(`${URL}/receiving`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        dispatch({
            type: types.GET_ALL_RECEIVING_INSPECTIONS,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.GET_ALL_RECEIVING_INSPECTIONS_FAIL,
            payload: error
        });
    }
};

export const getApprovedReceivingInspections = () => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_ACCEPTED_RECEIVING_INSPECTIONS_REQUEST,
        });
        const { data } = await axios.get(`${URL}/receiving/approved`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_ACCEPTED_RECEIVING_INSPECTIONS,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.GET_ACCEPTED_RECEIVING_INSPECTIONS_FAIL,
            payload: error
        });
    }
};

export const getReceivingInspection = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_RECEIVING_INSPECTION_REQUEST,
        });
        const { data } = await axios.get(`${URL}/receiving/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_RECEIVING_INSPECTION,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_RECEIVING_INSPECTION_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const updateReceivingInspection = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.UPDATE_RECEIVING_INSPECTION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/receiving/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.UPDATE_RECEIVING_INSPECTION,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.UPDATE_RECEIVING_INSPECTION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const deleteReceivingInspection = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.DELETE_RECEIVING_INSPECTION_REQUEST,
        });
        await axios.delete(`${URL}/receiving/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.DELETE_RECEIVING_INSPECTION,
            payload: id,
        });
    } catch (error) {
        dispatch({
            type: types.DELETE_RECEIVING_INSPECTION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const submitReceivingInspection = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.SUBMIT_RECEIVING_INSPECTION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/receiving/submit/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.SUBMIT_RECEIVING_INSPECTION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.SUBMIT_RECEIVING_INSPECTION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const inspectReceivingInspection = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.INSPECT_RECEIVING_INSPECTION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/receiving/inspect/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.INSPECT_RECEIVING_INSPECTION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.INSPECT_RECEIVING_INSPECTION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const rejectReceivingInspection = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.REJECT_RECEIVING_INSPECTION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/receiving/reject/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.REJECT_RECEIVING_INSPECTION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.REJECT_RECEIVING_INSPECTION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const reviewReceivingInspection = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.REVIEW_RECEIVING_INSPECTION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/receiving/review/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.REVIEW_RECEIVING_INSPECTION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.REVIEW_RECEIVING_INSPECTION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const declineReceivingInspection = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.DECLINE_RECEIVING_INSPECTION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/receiving/decline/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.DECLINE_RECEIVING_INSPECTION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.DECLINE_RECEIVING_INSPECTION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const approveReceivingInspection = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.APPROVE_RECEIVING_INSPECTION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/receiving/approve/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.APPROVE_RECEIVING_INSPECTION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.APPROVE_RECEIVING_INSPECTION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const disapproveReceivingInspection = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.DISAPPROVE_RECEIVING_INSPECTION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/receiving/disapprove/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.DISAPPROVE_RECEIVING_INSPECTION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.DISAPPROVE_RECEIVING_INSPECTION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};



//////////////////////////////////////////////
/////////////// Work Completion //////////////
//////////////////////////////////////////////
export const createWorkCompletion = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.CREATE_WORK_COMPLETION_REQUEST,
        });
        const { data } = await axios.post(`${URL}/workcompletion`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.CREATE_WORK_COMPLETION,
            payload: data.data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.CREATE_WORK_COMPLETION_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getWorkCompletions = () => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_ALL_WORK_COMPLETIONS_REQUEST,
        });
        const { data } = await axios.get(`${URL}/workcompletion`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        dispatch({
            type: types.GET_ALL_WORK_COMPLETIONS,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.GET_ALL_WORK_COMPLETIONS_FAIL,
            payload: error
        });
    }
};


export const getAcceptedWorkCompletions = () => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_ACCEPTED_WORK_COMPLETIONS_REQUEST,
        });
        const { data } = await axios.get(`${URL}/workcompletion/accepted`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_ACCEPTED_WORK_COMPLETIONS,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.GET_ACCEPTED_WORK_COMPLETIONS_FAIL,
            payload: error
        });
    }
};

export const getWorkCompletion = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_WORK_COMPLETION_REQUEST,
        });
        const { data } = await axios.get(`${URL}/workcompletion/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_WORK_COMPLETION,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_WORK_COMPLETION_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const updateWorkCompletion = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.UPDATE_WORK_COMPLETION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/workcompletion/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.UPDATE_WORK_COMPLETION,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.UPDATE_WORK_COMPLETION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const deleteWorkCompletion = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.DELETE_WORK_COMPLETION_REQUEST,
        });
        await axios.delete(`${URL}/workcompletion/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.DELETE_WORK_COMPLETION,
            payload: id,
        });
    } catch (error) {
        dispatch({
            type: types.DELETE_WORK_COMPLETION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const submitWorkCompletion = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.SUBMIT_WORK_COMPLETION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/workcompletion/submit/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.SUBMIT_WORK_COMPLETION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.SUBMIT_WORK_COMPLETION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};


export const verifyWorkCompletion = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.VERIFY_WORK_COMPLETION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/workcompletion/verify/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.VERIFY_WORK_COMPLETION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.VERIFY_WORK_COMPLETION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const acceptWorkCompletion = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.ACCEPT_WORK_COMPLETION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/workcompletion/accept/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.ACCEPT_WORK_COMPLETION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.ACCEPT_WORK_COMPLETION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const rejectWorkCompletion = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.REJECT_WORK_COMPLETION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/workcompletion/reject/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.REJECT_WORK_COMPLETION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.REJECT_WORK_COMPLETION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const reviewWorkCompletion = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.REVIEW_WORK_COMPLETION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/workcompletion/review/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.REVIEW_WORK_COMPLETION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.REVIEW_WORK_COMPLETION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const declineWorkCompletion = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.DECLINE_WORK_COMPLETION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/workcompletion/decline/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.DECLINE_WORK_COMPLETION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.DECLINE_WORK_COMPLETION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const approveWorkCompletion = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.APPROVE_WORK_COMPLETION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/workcompletion/approve/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.APPROVE_WORK_COMPLETION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.APPROVE_WORK_COMPLETION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const disapproveWorkCompletion = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.DISAPPROVE_WORK_COMPLETION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/workcompletion/disapprove/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.DISAPPROVE_WORK_COMPLETION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.DISAPPROVE_WORK_COMPLETION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};
